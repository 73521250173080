<template>
  <div class="row">
    <div class="col-lg-2 mb-3">
      <select class="form-control" v-model="selectedYear" @change="emit">
        <option v-for="r in yearOptions" :key="r">
          {{ r }}
        </option>
      </select>
    </div>
    <div class="col-lg-2 mb-3">
      <select class="form-control" v-model="selectedMonth"  @change="emit">
        <option v-for="r in monthOptions" :key="r.value" :value="r.value" >
          {{ r.name }}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";

export default {
  name: "AppYearMonthSelect",

  props: {
    value: {
      type: String,
      default: "",
    },

    yearFrom: {
      type: Number,
      default: 2022,
    },
  },

  data() {
    return {
      selectedYear: null,
      selectedMonth: null,
    };
  },

  computed: {
    yearOptions() {
      const currentYear = parseInt(dayjs().format("YYYY"));
      const r = [];
      for (let i = this.yearFrom; i <= currentYear; i++) {
        r.push(i);
      }
      return r;
    },

    monthOptions() {
      const r = [];
      for (let i = 1; i <= 12; i++) {
        const m = dayjs()
          .month(i - 1)
          .format("MMMM");
        r.push({ value: i, name: `${i} - ${m}` });
      }
      return r;
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      const date = dayjs(this.value);

      if (date.isValid()) {
        this.selectedYear = parseInt(dayjs(this.value).format("YYYY"));
        this.selectedMonth = parseInt(dayjs(this.value).format("MM"));
      } else {
        this.selectedYear = parseInt(dayjs().format("YYYY"));
        this.selectedMonth = parseInt(dayjs().format("MM"));

        this.emit();
      }
    },

    emit() {
      const date = `${this.selectedYear}-${dayjs()
        .month(this.selectedMonth - 1)
        .format("MM")}`;

      this.$emit('input', date);
    },
  },
};
</script>